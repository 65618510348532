.calender input{
    display: none;
    border: none;
}
.p-calendar-w-btn-right .p-button{
    border-left: 1px solid #ced4da;
}
.p-inputtext{
    width:200px;
}
.p-input-icon-left .p-inputtext{
    width:400px;
    padding-left: 40px;
}
.datatable .p-datatable .p-datatable-tbody>tr>td {
    padding: 6px 1rem;
}
/* .autocompletememname .p-inputtext{
    width:450px;
}
.autocompletememid .p-inputtext{
    width:136px;
}
.autocompletenicno .p-inputtext{
    width:150px;
} */